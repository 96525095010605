<template>
  <div>
    <button v-if="currentUser" @click="toggleEdit()">
      <template v-if="id">Edit pool item</template>
      <template v-if="!id">Add pool item</template>
    </button>
    <div v-if="edit" class="modal">
      <label><span>Title:</span> <input type="text" v-model="editable.title"></label>
      <!-- <label><span>Slug:</span> {{editable.title | slug}}</label> -->
      <label><span>Date:</span> <input type="datetime-local" v-model="editable.date"></label>
      <label><span>Body:</span> <textarea v-model="editable.body"></textarea></label>
      <label><span>Tags:</span></label>
      <label v-for="(tag, i) in editable.tags" :key="i">
        <span></span><input v-model="editable.tags[i]">
      </label>
      <label><button @click="addTag">Add tag</button></label>
      <label><span>img:</span> <input type="text" v-model="editable.img"></label>
      <label><span>f:</span> <input type="text" v-model="editable.f"></label>
      <label><span>mm:</span> <input type="text" v-model="editable.mm"></label>
      <label><span>iso:</span> <input type="text" v-model="editable.iso"></label>
      <label><span>shutter:</span> <input type="text" v-model="editable.shutter"></label>

      <button @click="save">Save</button>
      <button @click="erase">Delete</button>
      <button @click="cancel">Cancel</button>
    </div>

  </div>


</template>

<script>
// TODO: need to prevent duplicate slugs

import {
	mapState
} from 'vuex'

export default {
	name: 'editpool',

	props: ['id'],
	data() {
		return {
			edit: false,
			editable: {}
		}
	},

	beforeMount() {
		this.init();
	},
	computed: {
		...mapState('pool', {
			'pool': 'pool'
		}),
		...mapState('user', {
			'currentUser': 'currentUser'
		}),
		item() {
			if (this.id) {
				return this.pool[this.id] || {};
			} else {
				// new item
				return {
					tags: [this.$route.meta.tag || ""],
					date: new Date()
				}
			}
		},
	},
	methods: {
		init() {
			this.$store.dispatch('pool/get', this.id)
		},
		toggleEdit() {
			this.edit = !this.edit;
			if (this.edit) {
				this.editable = {
					id: this.id,
					title: this.item.title,
					date: this.filters.datetimeLocal(this.item.date),
					body: this.item.body,
					tags: [...this.item.tags],
					// img: this.item.img.replace(/\/pool\//,''),
					f: this.item.f,
					mm: this.item.mm,
					iso: this.item.iso,
					shutter: this.item.shutter
				};
				if (this.item.img) {
					this.editable.img = this.item.img.replace(/\/pool\//, '');
				}
			}
		},
		addTag() {
			if (this.editable.tags) {
				this.editable.tags.push("")
			} else {
				this.$set(this.editable, 'tags', [""]);
			}
		},
		save() {
			this.editable.slug = this.filters.slug(this.editable.title);
			this.$store.dispatch('pool/edit', this.editable);
			this.edit = false;
		},
		erase() {
			if (this.id) {
				this.$store.dispatch('pool/erase', this.id);
			}
			this.edit = false;
		},
		cancel() {
			this.edit = false;
		}
	}
}
</script>

<style>
</style>