<template>
  <component :is="compiled"> </component>
</template>

<script>
import Vue from 'vue';
import PoolList from '@/components/PoolList';
import CodeHighlight from '@/components/CodeHighlight';

/* use for pages or pool items that need to be compiled at runtime. 
If this ends up needing a ton of imports may split  it up into versions later */

export default {
	name: 'CompiledContent',
	props: {
		input: String
	},
	computed: {
		compiled() {
			if (this.input) {
				var ret = Vue.compile('<span>' + this.input + '</span>');
				ret.components = {
					PoolList,
					CodeHighlight
				}; // add any other necessary properties similarly
				return ret;
			}
		}
	}
}
</script>