<template>
  <div>
    <loading v-if="poolList.length == 0"></loading>
    <div v-else>
      <pool-list-grid v-if="type=='image'" :list="poolList"></pool-list-grid>
      <div v-if="!type || type=='list'">
        <div v-for="p in poolList" :key="p.id" class="flex">
          <div class="label">
            <span v-if="p.hasNewDate">{{p.date | formatDate("mmmm d, yyyy")}}: </span>
          </div>
          <div class="content">
            <router-link :to="p.path"><span v-html="p.title"></span></router-link>
          </div>
        </div>
      </div>
      <div v-if="type=='summary'">
        <div v-for="p in poolList" :key="p.id" class="flex">
          <div class="label">{{p.date | formatDate("mmmm d, yyyy")}}: </div>
          <div class="content">
            <router-link :to="p.path"><span v-html="p.title"></span></router-link>
            <div>{{p.body | truncate(150)}}</div>
          </div>
        </div>
        <hr>
      </div>
    </div>
  </div>
</template>

<script>
/* 
Currently only supports filtering by tag.  Depends on page to have performed the store get already

TODO paginate  (don't paginate on prerender, need to be able to find out whether it's live or not)

*/
import {
	mapState
} from 'vuex'
import PoolListGrid from '@/components/PoolListGrid';
import Loading from '@/components/Loading';
export default {
	name: 'PoolList',

	components: {
		PoolListGrid,
		Loading
	},
	props: [
		'tag', // tag to be listed
		'type', // image, summary, or list (default)
		'limit', // if number, show that many items.  if array of 2 numbers, start/end number. 1-indexed; use * for "all"
		'sort', // desc (default), asc)
		'path', // prepend this path onto the router link
	],
	computed: {
		...mapState('pool', {
			'pool': 'pool'
		}),
		poolList() {
			let ret = [];

			// filter by tag and prepend path
			for (let k in this.pool) {
				if (this.pool[k].tags.includes(this.tag)) {
					let clone = {
						...this.pool[k]
					};
					clone.path = (this.path) ? this.path + clone.slug + '.html' : './' + clone.slug + '.html';
					ret.push(clone)
				}
			}

			// sort
			ret = ret.sort((a, b) => {
				if (this.sort === 'asc') {
					return a.date - b.date // ascending
				} else {
					return b.date - a.date // descending
				}
			});

			// compare dates
			for (var i = 0, len = ret.length; i < len; i++) {
				if (i == 0 || !this.sameDay(ret[i].date, ret[i-1].date)) {
					ret[i].hasNewDate = true;
				}
			}

			// limit can be number or array, but it gets passed in as a string. Sanitize and eval:
			let parsedlimit = eval((this.limit || '').replace(/[^\d[],\*]/g, '').replace('*', 'Infinity'));
			if (typeof (parsedlimit) === "object") {
				ret = ret.slice(parsedlimit[0] - 1, parsedlimit[1])
			} else if (typeof (parsedlimit) === "number") {
				ret = ret.slice(0, parsedlimit)
			}
			return ret;
		}
	},
	methods: {
		sameDay(d1, d2) {
			return this.filters.formatDate(d1, 'yymmdd') == this.filters.formatDate(d2, 'yymmdd')
		}
	}
}
</script>

<style scoped lang="scss">
.flex {
	display: flex;
}

.flex .label {
	min-width: 140px;
	text-align: right;
	padding-right: 0.5em;
}

</style>